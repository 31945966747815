
import Vue, { PropType } from 'vue';

import ComponentMixIn from '@/modules/componentMixIn';
import { GOOGLE_ADMIN_CONSOLE_URL } from '@core/constants/links';
import { BUSINESS_PROFILE_SCOPE, IDP_INTEGRATION_PAGE } from '@/routes/constants';
import Subscription from '@/models/subscription';
import ProductsList from '@/modules/subscriptions/details/products/productsList/productsList.vue';
import {
  MAINTENANCE_SUPPORT,
  PRODUCT_KEY_NAME_DAAS,
  PRODUCT_KEY_NAME_PBI,
  PRODUCT_KEY_NAME_PDB,
  PRODUCT_KEY_NAME_PSW,
  PRODUCT_KEY_NAME_RAS,
  PRODUCT_KEY_NAME_RAS_AZMP
} from '@core/constants/subscriptions';
import SubscriptionMixIn from '@/modules/subscriptions/subscriptionMixIn';
import TranslationString from '@/models/translationString';

interface ISubscriptionOption {
  keyName: string;
  name: string | TranslationString;
  value: string | TranslationString;
  isExpired?: boolean;
}

export default Vue.extend({
  name: 'product',

  components: { ProductsList },

  mixins: [ComponentMixIn, SubscriptionMixIn],

  props: {
    subscription: {
      type: Object as PropType<Subscription>,
    },
    canEdit: {
      type: Boolean,
    },
    equalTo: {
      type: String,
    },
  },

  data () {
    return {
      BUSINESS_PROFILE_SCOPE,
      IDP_INTEGRATION_PAGE,
    };
  },

  computed: {
    subscriptionOptions (): ISubscriptionOption[] {
      const now = Date.now();

      const options: ISubscriptionOption[] = this.subscription.options
        .map(option => {
          return {
            keyName: option.keyName,
            // @ts-ignore  FIXME: https://jira.prls.net/browse/CPCLOUD-16280
            name: this.getResourceName(option.keyName),
            isExpired: option.licenses.objectType === 'datetime' &&
              new Date(option.licenses.limit * 1000).valueOf() < now,
            // @ts-ignore  FIXME: https://jira.prls.net/browse/CPCLOUD-16280
            value: this.resourceValue(option),
          };
        });
      const requiredMSField = [
        PRODUCT_KEY_NAME_PSW,
        PRODUCT_KEY_NAME_RAS,
        PRODUCT_KEY_NAME_DAAS,
        PRODUCT_KEY_NAME_PBI,
        PRODUCT_KEY_NAME_RAS_AZMP,
      ];
      if (requiredMSField.includes(this.subscription.firstProductKeyName) && !this.subscription.isPermanent) {
        let value = this.$t('Until {date}', { date: this.formatDate(this.subscription.expirationDate) });
        if (this.subscription.isRasAzmp) {
          value = this.$t('Valid for the time of use');
        } else if (this.subscription.isNeverExpiringSPLA) {
          value = this.$t('Unlimited');
        }
        options.push({
          keyName: MAINTENANCE_SUPPORT,
          // @ts-ignore  FIXME: https://jira.prls.net/browse/CPCLOUD-16280
          name: this.getResourceName(MAINTENANCE_SUPPORT),
          value: value as string,
          isExpired: this.subscription.isExpired,
        });
      }
      if (this.subscription.isPDE) {
        options.push({
          keyName: 'pde_management_portal',
          name: this.$t('Parallels Desktop Management Portal') as string,
          value: '',
        });
      }
      return options;
    },

    googleConsoleLink (): string {
      return `${GOOGLE_ADMIN_CONSOLE_URL}?hl=${this.$appData.session.language}`;
    },

    forecastQtyDelta (): string {
      const products = this.subscription.products.find(product => product.productKeyName === PRODUCT_KEY_NAME_PDB);
      if (!products) {
        return '';
      }
      const delta = products.licenses.nextPeriod - products.licenses.thisPeriod;
      return delta !== 0 ? `${delta}` : '';
    },

    showDowngrade (): boolean {
      const currentDate = new Date();
      return !!this.subscription.plannedDowngradeAt &&
        !!this.forecastQtyDelta &&
        this.subscription.plannedDowngradeAt > currentDate;
    },

    subscriptionDowngradeDate (): Date {
      const plannedDowngradeDate = new Date(this.subscription.plannedDowngradeAt);
      plannedDowngradeDate.setDate(plannedDowngradeDate.getDate() - 1);
      return plannedDowngradeDate;
    },
    showContractDetails (): boolean {
      const subscription = this.subscription;
      const isPde = subscription.isPDE;
      return isPde || this.canEdit && subscription.isPrimary && subscription.hasContract && !subscription.isBytebotStore;
    },
  },
});
