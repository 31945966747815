

import Vue, { PropType } from 'vue';
import ComponentMixIn from '@/modules/componentMixIn';
import SubscriptionMixIn from '@/modules/subscriptions/subscriptionMixIn';
import UniversalKey, { UniversalKeyType } from '@/models/universalKey';

export default Vue.extend({
  name: 'license-seats',
  mixins: [ComponentMixIn, SubscriptionMixIn],
  props: {
    universalKeys: {
      type: Array as PropType<UniversalKey[]>,
      default: () => [],
    },
  },
  computed: {
    occupiedLicenseSeats (): number {
      return (this.occupiedReservedPool + this.occupiedDynamicPool) || 0;
    },
    vacantLicenseSeats (): number {
      return this.universalKeys.map(key => key.vacant).reduce((acc, vacant) => acc + vacant, 0) - this.occupiedLicenseSeats;
    },
    occupiedReservedPool (): number {
      return this.reservedKeys.filter(key => key.used).reduce((acc, key) => acc + key.used, 0) || 0;
    },
    occupiedDynamicPool (): number {
      const dynamicCount = this.dynamicKeys.filter(key => key.used).reduce((acc, key) => acc + key.used, 0);
      const primaryCount = this.primaryKeys.filter(key => key.used).reduce((acc, key) => acc + key.used, 0);
      return (primaryCount + dynamicCount) || 0;
    },
    vacantReservedPool (): number {
      return (this.reservedKeys as UniversalKey[])?.filter(key => key.vacant).reduce((acc, key) => acc + key.vacant, 0) || 0;
    },
    vacantDynamicPool (): number {
      return (this.vacantLicenseSeats - this.vacantReservedPool) || 0;
    },
    reservedKeys (): UniversalKey[] {
      return (this.universalKeys as UniversalKey[]).filter((key) => key.type === UniversalKeyType.Reserved);
    },
    primaryKeys (): UniversalKey[] {
      return (this.universalKeys as UniversalKey[]).filter((key) => key.type === UniversalKeyType.Primary);
    },
    dynamicKeys (): UniversalKey[] {
      return (this.universalKeys as UniversalKey[]).filter((key) => key.type === UniversalKeyType.Dynamic);
    },
  },
});

