
import Vue from 'vue';
import { PDB_CONFIGURATION_PROFILES_KB_ARTICLE_URL } from '@core/common/links';
import SuspendSubscriptionRequest from '@/api/suspendSubscriptionRequest';
import Subscription from '@/models/subscription';
import { prodKeyToName } from '@core/constants/subscriptions';

export interface KeyConflictModalData {
  keyInfo: Subscription;
  existedSubscription: Subscription;
  isSuspendable: boolean;
  existedLicKey: string;
  registeredLicKey: string;
}

export default Vue.extend({
  name: 'key-conflict-modal',
  data () {
    return {
      isSuspendable: false,
      loading: false,
      registeredLicKey: '',
      existedLicKey: '',
      existingSubscription: {},
      keyInfo: {},
    };
  },
  computed: {
    kbLink (): string {
      return PDB_CONFIGURATION_PROFILES_KB_ARTICLE_URL(this.$appData.session.isoLocale);
    },
    registeredProductName (): string {
      return prodKeyToName((this.keyInfo as Subscription)?.firstProductKeyName) || '';
    },
    existingProductName (): string {
      return prodKeyToName((this.existingSubscription as Subscription)?.firstProductKeyName) || '';
    },
    existingSubscriptionExpirationDate (): Date | string {
      return (this.existingSubscription as Subscription)?.expirationDate || '';
    },
  },
  methods: {
    show (e, data: KeyConflictModalData) {
      this.registeredLicKey = data.registeredLicKey;
      this.isSuspendable = data.isSuspendable;
      this.existingSubscription = data?.existedSubscription;
      this.existedLicKey = data.existedLicKey;
      this.keyInfo = data.keyInfo;
    },
    sendEmail (): void {
    },
    closeModal (): void {
      this.$modal.hide();
    },
    openKbArticle () {
      window.open(this.kbLink, '_blank');
    },
    async suspendSubscription () {
      this.loading = true;
      try {
        const request = new SuspendSubscriptionRequest({ uuid: (this.existingSubscription as Subscription).uuid });
        await this.$api.authorizedCall(request);
      } catch (e) {
      } finally {
        this.loading = false;
      }
    },
  },
});
