

import { sortByDate, sortByValueWithPermanent } from '@core/common/sorters';
import ComponentMixIn from '@/modules/componentMixIn';
import SubscriptionUniversalKeysRequest from '@/api/subscriptionUniversalKeysRequest';
import UniversalKey, { UniversalKeyType } from '@/models/universalKey';
import LicenseKeyModal from './licenseKeyModal/index.vue';
import subscriptionInviteModal from '@/modules/desktop/subscriptionInvite/subscriptionInviteModal.vue';
import {
  ALLOWED_DOWNLOAD_LICENSE_KEY,
  PRODUCT_KEY_NAME_DAAS,
  PRODUCT_KEY_NAME_PBI,
  PRODUCT_KEY_NAME_PDB,
  PRODUCT_KEY_NAME_PDE,
  PRODUCT_KEY_NAME_PMM,
  PRODUCT_KEY_NAME_PMM_MDM,
  PRODUCT_KEY_NAME_PSW,
  PRODUCT_KEY_NAME_PTBB,
  PRODUCT_KEY_NAME_RAS,
  PRODUCT_KEY_NAME_RAS_AZMP,
  STORE_NAME_BYTEBOT
} from '@core/constants/subscriptions';
import { BUSINESS_PROFILE_SCOPE, USERS_PAGE } from '@/routes/constants';
import { ROLE_ALL } from '@/models/companyUser';
import Subscription, { STATUS_ALL } from '@/models/subscription';
import LicenseDownloadRequest from '@/api/licenseDownloadRequest';
import Vue, { PropType } from 'vue';
import LicenseReadyAwaitModal
  from '@/modules/subscriptions/details/licenseKeys/licenseReadyAwaitModal/licenseReadyAwaitModal.vue';
import LicenseSeats from '@/modules/subscriptions/details/licenseSeats/licenseSeats.vue';

const reSublicense = /^(?:Sublicense|License Key) (\d+)$/i;

const MAX_DATE = new Date('2050-01-01');

interface Counter {
  used: number;
  vacant: number;
  quota: number;
}

interface TableRow {
  licKey: string;
  type: string;
  quota: number;
  used: number;
  vacant: number;
  name: string;
  expiresOn: string;
  subscriptionUuid: string;
  hasSettings: boolean;
  uk: UniversalKey;
  users: number;
  devices: number;
}

export default Vue.extend({
  name: 'license-keys',
  mixins: [ComponentMixIn],
  components: {
    LicenseReadyAwaitModal,
    LicenseKeyModal,
    subscriptionInviteModal,
    LicenseSeats,
  },

  created (): void {
    this.load();
    this.$bus.$on('invitePdUsers', this.openInviteDialog);
  },

  destroyed (): void {
    this.$bus.$off('invitePdUsers', this.openInviteDialog);
  },

  props: {
    subscription: Object as PropType<Subscription>,
    session: Object,
    personalMode: {
      type: Boolean,
      default: true,
    },
    productKey: {
      type: String,
    },
  },

  data () {
    let columns;
    if (this.personalMode) {
      columns = ['name'];
    } else {
      if (this.subscription.hasProduct(PRODUCT_KEY_NAME_RAS)) {
        columns = this.subscription.isPostPaid ? ['name', 'type', 'quota', 'expiresOn', 'edit'] : ['name', 'type', 'quota', 'used', 'expiresOn', 'edit'];
      } else if (
        [PRODUCT_KEY_NAME_PSW, PRODUCT_KEY_NAME_PBI, PRODUCT_KEY_NAME_DAAS, PRODUCT_KEY_NAME_RAS_AZMP]
          .some((product) => this.subscription.hasProduct(product))
      ) {
        columns = ['name', 'type', 'quota', 'expiresOn', 'edit'];
      } else if (this.subscription.storeId === STORE_NAME_BYTEBOT) {
        columns = ['name', 'type', 'quota', 'users', 'devices'];
      } else if (this.subscription.hasProduct(PRODUCT_KEY_NAME_PDE)) {
        columns = ['name', 'type', 'quota', 'used', 'vacant', 'expiresOn', 'edit'];
      } else {
        columns = ['name', 'type', 'quota', 'used', 'vacant', 'expiresOn', 'edit'];
      }
    }

    return {
      columns: columns,
      universalKeys: [],
      options: {
        orderBy: {
          column: this.personalMode ? 'name' : 'type',
          ascending: !this.personalMode,
        },
        customSorting: {
          /**
             *  Primary key is always first
             */
          type (asc) {
            return (a, b) => sortByValueWithPermanent(a.type, b.type, asc, UniversalKeyType.Primary);
          },
          expiresOn: asc => {
            return (a, b) => {
              return sortByDate(
                a.uk.expirationDate || this.subscription.expirationDate || MAX_DATE,
                b.uk.expirationDate || this.subscription.expirationDate || MAX_DATE,
                asc
              );
            };
          },
        },
      },
      primaryKey: null,
      isLicenceDownloading: false,
      isAwaitingForDownloadLink: false,
    };
  },

  computed: {
    showLicenseSeats (): boolean {
      return this.subscription.hasProduct(PRODUCT_KEY_NAME_PDE);
    },
    keys (): UniversalKey[] {
      return this.universalKeys.filter((uk) => {
        // Exclude UK w/o resource (to avoid bug with deleted subsets) CPCLOUD-8735
        return uk.resources.length > 0;
      });
    },

    rows (): TableRow[] {
      return this.keys.map((key) => this.buildRow(key));
    },

    totalUsed (): number {
      let totalUsed = 0;
      this.keys.forEach((key) => {
        totalUsed += key.used;
      });
      return totalUsed;
    },

    inviteAvailable (): boolean {
      return this.isPdbSubscription;
    },

    subsetAllowed (): boolean {
      return !this.personalMode && this.subscription.isPrimary && this.subscription.subsetAllowed;
    },

    tableName (): string {
      return this.personalMode ? 'licenseKeysPersonal' : 'licenseKeysBusiness';
    },

    inviteTitle (): string {
      if (this.isPdbSubscription) {
        return this.$t('Invite Parallels Desktop Users');
      } else {
        return this.$t('Invite Parallels Access Users');
      }
    },

    isPdbSubscription (): boolean {
      return this.subscription && this.subscription.hasProduct(PRODUCT_KEY_NAME_PDB);
    },

    isPdeSubscription (): boolean {
      return this.subscription && this.subscription.hasProduct(PRODUCT_KEY_NAME_PDE);
    },

    isRasSubscription (): boolean {
      return this.subscription && this.subscription.hasProduct(PRODUCT_KEY_NAME_RAS);
    },
  },

  methods: {
    isShowDownloadButton (universalKey): boolean {
      const firstResource = universalKey?.firstResource;
      return firstResource && ALLOWED_DOWNLOAD_LICENSE_KEY.includes(firstResource.productKeyName);
    },
    isDownloadButtonDisabled (universalKey): boolean {
      return this.subscription.isExpired || this.subscription.isSuspended || universalKey?.isExpired;
    },
    acquireNextSubsetNumber (): number {
      let i = this.universalKeys.length - 1;
      let found;
      for (; i > 0; i--) {
        found = this.universalKeys[i].name.match(reSublicense);
        if (found) {
          return Number(found[1]) + 1;
        }
      }
      return 1;
    },

    getUniversalKeyRequest (): SubscriptionUniversalKeysRequest {
      return new SubscriptionUniversalKeysRequest({
        subscriptionUuid: this.subscription.uuid,
        includeSubsets: true,
        service: this.subscription.targetServiceName,
      });
    },

    onChangeSubset (): void {
      this.getUniversalKeyRequest().dropCache();
      // Subscription will be loaded again, this component will be re-created and loaded
      this.$emit('reload');
    },

    load (): void {
      const request = this.getUniversalKeyRequest();
      this.authorizedCall(request).then(() => {
        this.universalKeys = request.getUniversalKeys();
        this.primaryKey = request.getPrimaryKey();
      });
    },

    getExpiresOn (uk): string {
      if (this.subscription.isNeverExpiringSPLA || this.subscription.isRasAzmp) {
        if (uk.isPrimary) {
          return '—';
        } else {
          return this.formatDate(uk.expirationDate) || '—';
        }
      }
      return this.formatDate(uk.expirationDate || this.subscription.expirationDate) || this.$t('Permanent');
    },

    getCounters (uk): Counter {
      const counters: Counter = {
        used: 0,
        vacant: 0,
        quota: 0,
      };

      const resource = this.productKey && uk.resources.find((x) => x.productKeyName === this.productKey);
      if (resource) {
        counters.used = resource.usage;
        counters.vacant = resource.available;
        counters.quota = uk.isLimited ? resource.limit : this.$t('Unlimited');
      } else {
        counters.used = uk.used;
        counters.vacant = uk.isLimited ? uk.vacant : this.$t('Unlimited');
        counters.quota = uk.isLimited ? uk.quota : this.$t('Unlimited');
      }
      return counters;
    },

    buildRow (uk): TableRow {
      if (uk.isPrimary && !uk.name) {
        uk.name = this.$t('Primary Key');
      }

      const counters = this.getCounters(uk);
      let used = counters.used;
      if (this.isRasSubscription) {
        used = counters.used || undefined;
      }

      return {
        licKey: uk.licKey,
        type: this.$t(uk.type),
        quota: counters.quota,
        used,
        vacant: counters.vacant,
        name: uk.name,
        expiresOn: this.getExpiresOn(uk),
        subscriptionUuid: uk.subscriptionUuid,
        hasSettings: this.isKeySettingsAvailable(uk),
        uk: uk,
        users: this.subscription.bytebotUsersAmount,
        devices: this.subscription.bytebotDevicesAmount,
      };
    },

    isKeySettingsAvailable (key): boolean {
      if (key.type === 'Primary' && this.session.isBusinessAdmin && this.subscription.permissionsManageable) {
        return true;
      } else if (key.type !== 'Primary' && this.subsetAllowed) {
        return true;
      }
      return false;
    },

    openSettings (uk): void {
      // @ts-ignore FIXME: https://jira.prls.net/browse/CPCLOUD-16280
      this.$refs.subscriptionModal.show(uk);
    },

    openInviteDialog (uk): void {
      if (this.isPdbSubscription) {
        const modal = this.$refs.subscriptionInviteModal;
        // @ts-ignore FIXME: https://jira.prls.net/browse/CPCLOUD-16280
        modal.reset();
        // @ts-ignore FIXME: https://jira.prls.net/browse/CPCLOUD-16280
        modal.show(uk.subsetUuid || uk.subscriptionUuid);
      } else {
        this.$router.push(
          { name: BUSINESS_PROFILE_SCOPE, params: { page: USERS_PAGE }, query: { role: ROLE_ALL, status: STATUS_ALL } }
        );
      }
    },

    createNewSubset (): void {
      if (this.subscription.isRasPostpaid || this.subscription.firstProductAvailable) {
        // @ts-ignore FIXME: https://jira.prls.net/browse/CPCLOUD-16280
        this.$refs.subscriptionModal.show();
      } else {
        this.$modal.show('noLicensesAvailable');
      }
    },

    isClickableUsedCol (row): any {
      return row.used && this.getUsedColRouteParams(row.licKey);
    },

    getUsedColRouteParams (licKey): {name: string; query: {text: string}} {
      const checkProduct = (...productKeyNames) => productKeyNames.includes(this.productKey || this.subscription.firstProductKeyName);

      if (checkProduct(PRODUCT_KEY_NAME_PDB)) {
        return { name: 'desktopComputers', query: { text: licKey } };
      } else if (checkProduct(PRODUCT_KEY_NAME_PMM, PRODUCT_KEY_NAME_PMM_MDM)) {
        return { name: 'pmmServers', query: { text: licKey } };
      } else if (checkProduct(PRODUCT_KEY_NAME_PTBB)) {
        return { name: 'toolboxComputers', query: { text: licKey } };
      }
    },

    async apiDownloadLicense (universalKey) {
      const request = new LicenseDownloadRequest({ universalKey });
      this.isLicenceDownloading = true;
      return request.downloadLicenseFile(this.$api);
    },

    async downloadLicense (universalKey, onReady?: ()=> void) {
      let isModalShowed = false;
      await retry(
        () => this.apiDownloadLicense(universalKey),
        () => {
          if (!isModalShowed) {
            this.isAwaitingForDownloadLink = true;
            this.$modal.show(LicenseReadyAwaitModal);
            isModalShowed = true;
          }
        },
        () => this.isAwaitingForDownloadLink,
        3000);
      this.isLicenceDownloading = false;
      this.isAwaitingForDownloadLink = false;
      if (isModalShowed) {
        // @ts-ignore FIXME: https://jira.prls.net/browse/CPCLOUD-16280
        this.$refs.licenseReadyAwaitModal.hide();
        isModalShowed = false;
      }
      if (typeof onReady === 'function') {
        onReady();
      }
    },

    cancelAwaitingDownloadLink () {
      this.isAwaitingForDownloadLink = false;
    },
  },
});

export const retry = async (
  callback: ()=> (Promise<void>),
  onSleep: ()=> void,
  isActual: ()=> boolean,
  retryIntervalMs: number
): Promise<void> => {
  try {
    return await callback();
  } catch (error) {
    onSleep();
    await sleep(retryIntervalMs);
    if (isActual()) {
      return retry(callback, onSleep, isActual, retryIntervalMs);
    }
  }
};

const sleep = (ms = 0) => new Promise((resolve) => {
  const timeoutRef = setTimeout(() => {
    clearTimeout(timeoutRef);
    return resolve(true);
  }, ms);
});

