/**
 * File: configurationProfilesTable.js
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import componentMixin from '@/modules/componentMixIn';
import PdBulkDeleteConfigurationProfileRequest from '@/api/pdBulkDeleteConfigurationProfileRequest';
import ConfirmationModal from '@/ui/confirmationModal';
import PdCreateUpdateConfigurationProfileRequest from '@/api/pdCreateUpdateConfigurationProfileRequest';
import CombinedApiRequest from '@core/api/combinedApiRequest';
import { PDB_CONFIGURATION_PROFILES_KB_ARTICLE_URL } from '@core/common/links';

export default {
  name: 'configuration-profiles-table',

  mixins: [componentMixin],

  components: { ConfirmationModal },

  props: {
    configurationProfiles: {
      type: Array,
    },
  },

  data () {
    return {
      table: {
        columns: [],
        selectedIds: [],
      },
      sortBy: {
        column: 'name',
        ascending: true,
      },
    };
  },

  methods: {
    callAction (params) {
      this[params.action](params.items);
    },

    createConfigurationProfile () {
      this.$emit('createConfigurationProfileClick');
    },

    showConfirmDeleteModal () {
      this.$refs.confirmDeleteModalModal.show({
        message: this.$tc(
          'Will be deleted {amount} selected configuration profiles.',
          this.selectedForDeleteConfigurationProfiles.length,
          { amount: this.selectedForDeleteConfigurationProfiles.length }),
        success: this.onDeleteConfirmed,
        ids: this.selectedForDeleteConfigurationProfiles.map((item) => { return item.id; }),
      });
    },

    onDeleteConfirmed (data) {
      this.authorizedCall(new PdBulkDeleteConfigurationProfileRequest({ ids: data.ids })).then(() => {
        this.$emit('refresh');
        this.showToast(
          $tc('{amount} configuration profiles have been removed.',
            data.ids.length,
            { amount: data.ids.length }
          )
        );
      });
    },

    onConfigurationProfileClick (configurationProfile) {
      this.$emit('configurationProfileClick', configurationProfile);
    },

    duplicate (profiles) {
      const combinedRequest = new CombinedApiRequest({ integrity: true });

      profiles.forEach((item, i) => {
        const data = {
          name: item.name + ' Duplicate',
          templateVersion: item.templateVersion[0],
          values: item.values,
        };
        combinedRequest.addRequest(i, new PdCreateUpdateConfigurationProfileRequest(data));
      });

      this.authorizedCall(combinedRequest).then(() => {
        this.$emit('refresh');
        this.$toast.show({
          text: this.$tc('{amount} configuration profiles have been duplicated.', profiles.length, { amount: profiles.length }),
        });
      });
    },
    openKbArticle () {
      window.open(PDB_CONFIGURATION_PROFILES_KB_ARTICLE_URL(this.$appData.session.isoLocale));
    },
  },

  computed: {
    selectedConfigurationProfiles () {
      return this.configurationProfiles.filter((item) => this.table.selectedIds.includes(item.id));
    },

    selectedForDeleteConfigurationProfiles () {
      return this.selectedConfigurationProfiles.filter((item) => (!item.hostsCnt && !item.subscriptionsCnt));
    },
    actions () {
      const options = [{
        value: 'showConfirmDeleteModal',
        text: this.$t('Delete ({count})', { count: this.selectedForDeleteConfigurationProfiles.length }),
        disabled: !this.selectedForDeleteConfigurationProfiles.length,
      }, {
        value: 'duplicate',
        text: this.$t('Duplicate ({count})', { count: this.selectedConfigurationProfiles.length }),
        disabled: !this.selectedConfigurationProfiles.length,
      }];

      return {
        id: 'id',
        options,
      };
    },
    columns () {
      return {
        options: [
          { text: this.$t('Name'), value: 'name' },
          { text: this.$t('Last Change'), value: 'createdAt', type: 'date' },
          { text: this.$t('Number of Computers'), value: 'hostsCnt' },
          { text: this.$t('Number of License Keys'), value: 'subscriptionsCnt' },
        ],
      };
    },
  },

};
