<copyright>
File: productCardPDE.vue

Copyright:
Copyright © 2023 Parallels International GmbH. All rights reserved.
</copyright>

<template lang="pug">
  div
    card(type="product", :equalTo="equalTo", v-bind:class="[ defaultClass ]", icon="pde", data-qa="pde-card")
      template(slot="title")
        .text-ellipsis(data-qa="pde-product-name") {{ $t(PRODUCT_NAME_PDE) }}

      template(slot="actions")
        icon(name="download", @click="$router.push(getDownloadRoute)", data-qa="pde-download-icon")

      template(slot="about")
        span(v-text="$tc('{limit} license seats', item.totalLimit, { limit: item.totalLimit })", data-qa="pde-total-limit")

      template(slot="info")
        list.padding-bottom-xs
          router-link(
            :to="{ name: PDE_SUBPAGE_SUBSCRIPTION_DETAILS, params: { id: subscription.uuid, product: item.productKeyName } }",
            :data-name="$name('router-link-subscription-details-page')"
          )
            .row
              .col-xs-9.text-ellipsis {{ subscriptionItemText }}
              .col-xs-1
                icon(v-if="subscription.isExpired" name="alert").pull-right
                icon(v-else-if="subscription.isExpiringSoon" name="alert-yellow").pull-right
                span(v-else) &nbsp;
              .col-xs-2.text-right.text-link.text-bold {{ subscription.mainPeriodEndsAt | date }}

          // TODO will be enabled later
          //router-link(
          //  :to="{ name: PDE_SSO_USERS_PAGE }",
          //  :data-name="$name('router-link-users-with-licenses-page')"
          //)
          //  .row
          //    .col-xs-9.text-ellipsis {{ $t('SSO Users With Licenses') }}
          //    .col-xs-3.text-right.text-link.text-bold {{ subscription.firstProductUsage }}

          router-link(
            :to="{ name: PDE_COMPUTERS_PAGE }",
            :data-name="$name('router-link-desktop-computers')"
          )
            .row
              .col-xs-9.text-ellipsis(data-qa="pde-registered-computers-lbl") {{ $t('Registered computers') }}
              .col-xs-3.text-right.text-link.text-bold
                loader.pull-right(:loading="activeComputersLoading", size="2", data-qa="pde-registered-computers-count") {{ activeComputers }}

          template(v-if="isBusinessAdmin")
            .row(v-if="isCepOptionAccessible").custom-style-info
              .col-xs-10.text-ellipsis(data-qa="pde-enable-customer-exp-prgm-lbl") {{ $t('Enable Customer Experience Program') }}
              .col-xs-1
                icon(name="info-small", @click="openCepInfo", data-qa="pde-customer-exp-prgm-icon").pull-right
              .col-xs-1
                loader.pull-right(:loading="setCepOptionLoading", size="2")
                  checkbox(
                    :val="cepOption",
                    @change="setCepOption",
                    :data-name="$name('checkbox-cep-option')",
                    data-qa="pde-customer-exp-prgm-checkbox"
                  )
      template(slot="menu")
        btn.block-xs.margin-right-2x-sm.margin-top-2x(v-if="isBusinessAdmin" target="_blank", size="small", color="white", :href="pdePortalLink", data-qa="pdb-go-to-admin-portal-button")
          | {{ $t('Management Portal') }}
        btn.block-xs.margin-right-2x-sm.margin-top-2x(
          v-if="inviteAvailable",
          @click="$refs.subscriptionInviteModal.show()",
          size="small",
          color="white",
          :data-name="$name('button-invite-users')",
          data-qa="pdb-invite-users-button"
        )
          | {{ $t('Invite Users') }}
        btn.block-xs.margin-top-2x(target="_blank", size="small", color="white", @click="onSupportClick", data-qa="pde-support-button")
          | {{ $t('Support') }}

        subscriptionInviteModal(
          v-if="inviteAvailable",
          ref="subscriptionInviteModal",
          :product="item.productKeyName",
          :session="session",
          :data-name="$name('subscription-invite-modal')"
        )
</template>

<script>
// todo
// // {limit} license seats

import ProductCardMixIn from './productCardMixIn';
import { PRODUCT_KEY_NAME_PDE, PRODUCT_NAME_PDE } from '@core/constants/subscriptions';
import subscriptionInviteModal from '@/modules/desktop/subscriptionInvite/subscriptionInviteModal.vue';
import {
  DESKTOP_SSO_USERS_PAGE,
  GO_PAGE,
  PDE_COMPUTERS_PAGE,
  PDE_DOWNLOADS_PAGE,
  PDE_SSO_USERS_PAGE,
  PDE_SUBPAGE_SUBSCRIPTION_DETAILS,
  PDE_SUBPAGE_SUBSCRIPTIONS
} from '@/routes/constants';
import Vue from 'vue';
import ComponentMixIn from '@/modules/componentMixIn';
import PdComputersRequest from '@/api/pdComputersRequest';

export default Vue.extend({
  name: 'product-card-pde',
  mixins: [ComponentMixIn, ProductCardMixIn],
  props: ['subscriptions'],
  components: { subscriptionInviteModal },
  data () {
    return {
      PDE_DOWNLOADS_PAGE,
      PDE_SUBPAGE_SUBSCRIPTIONS,
      DESKTOP_SSO_USERS_PAGE,
      PRODUCT_NAME_PDE,
      GO_PAGE,
      PRODUCT_KEY_NAME_PDE,
      PDE_SUBPAGE_SUBSCRIPTION_DETAILS,
      PDE_COMPUTERS_PAGE,
      PDE_SSO_USERS_PAGE,
      activeComputersLoading: true,
      activeComputers: null,
    };
  },
  created: function () {
    const activeComputersRequest = new PdComputersRequest({
      activated: true,
      totalOnly: true,
      product: this.item.productKeyName,
    });

    this.authorizedCall(activeComputersRequest, 'activeComputersLoading')
      .then((data) => {
        this.activeComputers = data.total;
      });
  },

  methods: {
    sortSubscriptionsByDate (subscriptions) {
      return subscriptions.sort((a, b) => a.mainPeriodEndsAt.getDate() - b.mainPeriodEndsAt.getDate());
    },
  },
  computed: {
    inviteAvailable () {
      return this.item.productKeyName === PRODUCT_KEY_NAME_PDE;
    },
    isBusinessAdmin () {
      return this.$appData.session.isBusinessAdmin;
    },

    pdePortalLink () {
      return this.$appData.session.pdaPortalUrl;
    },

    subscription () {
      const pdeSubscriptions = this.subscriptions.filter((s) => s.isPDE);
      const activeSubscriptions = pdeSubscriptions.filter((s) => s.isActive);
      return activeSubscriptions.length ? this.sortSubscriptionsByDate(activeSubscriptions)[0] : this.sortSubscriptionsByDate(pdeSubscriptions)[0];
    },
    subscriptionItemText: function () {
      if (this.subscription.isExpired) {
        return this.$t('Subscription Expired');
      } else if (this.subscription.isExpiringSoon) {
        return this.$t('Subscription Expires');
      } else {
        return this.$t('Subscription Renewal');
      }
    },
  },
});

</script>

<style scoped lang="sass">
.custom-style-info
  display: flex
  align-items: center
.custom-style-info /deep/
  .info-small
    background-color: #3D73D8 !important
    .icon
      margin-top: $grid-step / 3
.checkbox
  margin-top: -$grid-step / 3
</style>
