import { render, staticRenderFns } from "./products.vue?vue&type=template&id=6eac56c8&scoped=true&lang=pug"
import script from "./products.vue?vue&type=script&lang=ts"
export * from "./products.vue?vue&type=script&lang=ts"
import style0 from "./products.vue?vue&type=style&index=0&id=6eac56c8&prod&scoped=true&lang=sass"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6eac56c8",
  null
  
)

/* custom blocks */
import block0 from "./products.vue?vue&type=custom&index=0&blockType=copyright"
if (typeof block0 === 'function') block0(component)

export default component.exports