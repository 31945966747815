/**
 * File: links.ts
 *
 * Copyright © 2022 Parallels International GmbH. All rights reserved.
 *
 * */

import { getBulaLanguage, getWebsiteLanguage } from '@core/constants/langs';

export const PDB_CONFIGURATION_PROFILES_KB_ARTICLE_URL = function (isoLocale: string) {
  const language = getWebsiteLanguage(isoLocale);
  return `https://kb.parallels.com/en/129240?language=${language}`;
};

export const CUSTOMER_EXPERIENCE_PROGRAM_URL = function (isoLocale: string) {
  const language = getWebsiteLanguage(isoLocale);
  return `https://www.parallels.com/${language}/support/pcep/`;
};

export const BULA_TEXT_URL = function (isoLocale: string) {
  const language = getBulaLanguage(isoLocale);
  return `https://www.corel.com/include/bula/${language}.html`;
};
