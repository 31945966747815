/**
 * File: subscriptions.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */
import { Dictionary } from '@core/common/types';

export const KEY_NAME_PDL = 'pd_lite';
export const KEY_NAME_RAS_IN_APP_TRIAL = 'ras_in_app_trial';
export const KEY_NAME_PMM = 'pmm_by_subscription';
export const KEY_NAME_PMM_MDM = 'pmm_mdm_by_subscription';

export const PRODUCT_KEY_NAME_PAX = 'pax';
export const PRODUCT_KEY_NAME_PAXB = 'paxb';
export const PRODUCT_KEY_NAME_PAXS = 'paxs';
export const PRODUCT_KEY_NAME_PDL = 'pdlite';
export const PRODUCT_KEY_NAME_PD = 'pd';
export const PRODUCT_KEY_NAME_PDB = 'pdb';
export const PRODUCT_KEY_NAME_PDB_PER_USER = 'pdb_per_user';
export const PRODUCT_KEY_NAME_PDFC = 'pdfc';
export const PRODUCT_KEY_NAME_PDFM = 'pdfm';
export const PRODUCT_KEY_NAME_PDFM_PRO = 'pdfm_pro';
export const PRODUCT_KEY_NAME_PDPRO = 'pdpro';
export const PRODUCT_KEY_NAME_PDFC_PURCHASE = 'pdfc-purchase'; // Fake key name for PDFC purchase dashboard card
export const PRODUCT_KEY_NAME_RAS = 'ras';
export const PRODUCT_KEY_NAME_PTB = 'ptb';
export const PRODUCT_KEY_NAME_PTB_DVT = 'ptb-dvt';
export const PRODUCT_KEY_NAME_PTB_DNST = 'ptb-dnst';
export const PRODUCT_KEY_NAME_PMM = 'pmm';
export const PRODUCT_KEY_NAME_PMM_MDM = 'pmm_mdm';
export const PRODUCT_KEY_NAME_PTBB = 'ptbb';
export const PRODUCT_KEY_NAME_PTBB_PER_USER = 'ptbb';
export const PRODUCT_KEY_NAME_PTB_HDT = 'ptb-hdt';
export const PRODUCT_KEY_NAME_PTB_CVT = 'ptb-cvt';
export const PRODUCT_KEY_NAME_MY_ACCOUNT = 'myacc';
export const PRODUCT_KEY_NAME_PER_INCIDENT_SUPPORT = 'per_incident_support';
export const PRODUCT_KEY_NAME_PAX_DONOR = 'pax_donor';
export const PRODUCT_KEY_NAME_PDFM8 = 'pdfm8';
export const PRODUCT_KEY_NAME_PDFM8_STM = 'pdfm8stm';
export const PRODUCT_KEY_NAME_PDFM9 = 'pdfm9';
export const PRODUCT_KEY_NAME_PDFM9_STM = 'pdfm9stm';
export const PRODUCT_KEY_NAME_PDFM10 = 'pdfm10';
export const PRODUCT_KEY_NAME_PDFM11_PRO = 'pdfm11_pro';
export const PRODUCT_KEY_NAME_PDFM11 = 'pdfm11';
export const PRODUCT_KEY_NAME_PDFM12 = 'pdfm12';
export const PRODUCT_KEY_NAME_PDFM13 = 'pdfm13';
export const PRODUCT_KEY_NAME_PDFM14 = 'pdfm14';
export const PRODUCT_KEY_NAME_PDFM15 = 'pdfm15';
export const PRODUCT_KEY_NAME_PDFM16 = 'pdfm16';
export const PRODUCT_KEY_NAME_PVAD = 'pvad';
export const PRODUCT_KEY_NAME_PSW = 'awg';
export const PRODUCT_KEY_NAME_DAAS = 'daas';
export const PRODUCT_KEY_NAME_PBI = 'pbi';
export const PRODUCT_KEY_NAME_RAS_AZMP = 'ras-azmp';
export const PRODUCT_KEY_NAME_PDE = 'pde';

export const PRODUCT_TYPE_NAME_PSW = 'awingu';

export const PRODUCTS = {
  [PRODUCT_KEY_NAME_PAX]: 'PAX',
  [PRODUCT_KEY_NAME_PAXB]: 'PAX BIZ',
  [PRODUCT_KEY_NAME_PDL]: 'PDFM AS',
  [PRODUCT_KEY_NAME_PDB]: 'PDFM BIZ',
  [PRODUCT_KEY_NAME_PDB_PER_USER]: 'PDFM BIZ',
  [PRODUCT_KEY_NAME_PDFC]: 'PDFC BIZ',
  [PRODUCT_KEY_NAME_PDFM]: 'PDFM',
  [PRODUCT_KEY_NAME_PDFM_PRO]: 'PDFM PRO',
  [PRODUCT_KEY_NAME_RAS]: 'RAS',
  [PRODUCT_KEY_NAME_PTB]: 'TB',
  [PRODUCT_KEY_NAME_PTB_DVT]: 'TB-DV',
  [PRODUCT_KEY_NAME_PTB_DNST]: 'TB-DS',
  [PRODUCT_KEY_NAME_PTB_HDT]: 'TB-HD',
  [PRODUCT_KEY_NAME_PTB_CVT]: 'TB-CV',
  [PRODUCT_KEY_NAME_PTBB]: 'TB BIZ',
  [PRODUCT_KEY_NAME_PMM]: 'PMM',
  [PRODUCT_KEY_NAME_PMM_MDM]: 'MDM',
  [PRODUCT_KEY_NAME_PDFM8]: 'PD8',
  [PRODUCT_KEY_NAME_PDFM8_STM]: 'PD8 STM',
  [PRODUCT_KEY_NAME_PDFM9]: 'PD9',
  [PRODUCT_KEY_NAME_PDFM9_STM]: 'PD9 STM',
  [PRODUCT_KEY_NAME_PDFM10]: 'PD10',
  [PRODUCT_KEY_NAME_PDFM11_PRO]: 'PD11 PRO',
  [PRODUCT_KEY_NAME_PDFM11]: 'PD11',
  [PRODUCT_KEY_NAME_PDFM12]: 'PD12',
  [PRODUCT_KEY_NAME_PDFM13]: 'PD13',
  [PRODUCT_KEY_NAME_PDFM14]: 'PD14',
  [PRODUCT_KEY_NAME_PDFM15]: 'PD15',
  [PRODUCT_KEY_NAME_PDFM16]: 'PD16',
  [PRODUCT_KEY_NAME_PVAD]: 'PVAD',
  [PRODUCT_KEY_NAME_PSW]: 'AWG',
  [PRODUCT_KEY_NAME_RAS_AZMP]: 'RAS-AZMP',
  [PRODUCT_KEY_NAME_PDE]: 'PDE'
};

export const PD_PRO_BUY_PUBLIC_ID = 'CB_PD_PRO_1Y';
export const PER_INCIDENT_SUPPORT_PUBLIC_ID = 'CB_PER_INCIDENT_SUPPORT';
export const PD_UPGRADE_TO_PRO_PUBLIC_ID = 'CB_PD_PRO_UPG_1Y';
export const AWG_MAINTENANCE_AND_SUPPORT_PUBLIC_ID = 'AWG-MS-SUB-1D';
export const PDPRO_BY_SUBSCRIPTION_KEY_NAME = 'pdfm_pro_by_subscription';

export const RKN_UPGRADE_INSURANCE = 'ras_upgrade_insurance';
export const RKN_STD_SUPPORT = 'ras_standard_support';
export const AWG_MAINTENANCE_SUPPORT = 'awg_maintenance_and_support';
export const MAINTENANCE_SUPPORT = 'maintenance_and_support';

export const RESOURCE_PDFM_BY_SUBSCRIPTION_KEY_NAME = 'pdfm_by_subscription';
export const RESOURCE_PDFM_PRO_BY_SUBSCRIPTION_KEY_NAME = 'pdfm_pro_by_subscription';
export const RESOURCE_PDB_BY_SUBSCRIPTION_KEY_NAME = 'pdb_by_subscription';
export const RESOURCE_PDB_PER_USER_BY_SUBSCRIPTION_KEY_NAME = 'pdb_per_user_by_subscription';
export const RESOURCE_PTB_FULL_BY_SUBSCRIPTION_KEY_NAME = 'ptb_full_by_subscription';
export const RESOURCE_PTBB_BY_SUBSCRIPTION_KEY_NAME = 'ptb_business_by_subscription';
export const RESOURCE_PTBB_PER_USER_BY_SUBSCRIPTION_KEY_NAME = 'ptb_business_per_user_by_subscription';
export const RESOURCE_PMM_BY_SUBSCRIPTION_KEY_NAME = 'pmm_by_subscription';
export const RESOURCE_RAS_PERMANENT_KEY_NAME = 'ras_permanent';
export const RESOURCE_RAS_PREMIER_SUPPORT_KEY_NAME = 'ras_premier_support';
export const RESOURCE_RAS_SENIOR_SUPPORT_KEY_NAME = 'ras_senior_support';
export const RESOURCE_RAS_STANDARD_SUPPORT_KEY_NAME = 'ras_standard_support';
export const RESOURCE_RAS_UPGRADE_INSURANCE_KEY_NAME = 'ras_upgrade_insurance';
export const RESOURCE_RAS_STD_BY_SUBSCRIPTION_KEY_NAME = 'ras_std_by_subscription';
export const RESOURCE_PAXB_BY_SUBSCRIPTION_KEY_NAME = 'pax_for_business_by_subscription';
export const RESOURCE_PAX_BY_SUBSCRIPTION_KEY_NAME = 'pax_by_subscription';
export const RESOURCE_PAXS_BY_SUBSCRIPTION_KEY_NAME = 'pax_support_by_subscription';
export const RESOURCE_PDL_KEY_NAME = 'pd_lite';
export const RESOURCE_PDFC_PERMANENT_KEY_NAME = 'pdfc_business_permanent';
export const RESOURCE_PVAD_BY_SUBSCRIPTION_KEY_NAME = 'pvad_by_subscription';
export const RESOURCE_AWG_CUSR_BY_SUBSCRIPTION = 'awg_cusr_by_subscription';
export const RESOURCE_AWG_CUSR_PERMANENT = 'awg_cusr_permanent';
export const RESOURCE_AWG_NUSR_BY_SUBSCRIPTION = 'awg_nusr_by_subscription';
export const RESOURCE_AWG_NUSR_PERMANENT = 'awg_nusr_permanent';
export const RESOURCE_AWG_MAINTENANCE_AND_SUPPORT = 'awg_maintenance_and_support';
export const RESOURCE_DAAS_NUSR_BY_SUBSCRIPTION = 'daas_nusr_by_subscription';
export const RESOURCE_RAS_AZMP_CUSR_BY_SUBSCRIPTION = 'ras_azmp_cusr_by_subscription';
export const RESOURCE_PDE_BY_SUBSCRIPTION = 'pde_by_subscription';

export const PAX_PERSONAL_ONE_YEAR_PUBLIC_ID = 'pax_personal_1year';
export const PAX_PERSONAL_TWO_YEARS_PUBLIC_ID = 'pax_personal_2years';

export const PAX_BUSINESS_ONE_YEAR_PUBLIC_ID = 'pax_business_1year';
export const PAX_BUSINESS_TWO_YEARS_PUBLIC_ID = 'pax_business_2years';

export const STORE_NAME_AAS = 'aas';
export const STORE_NAME_CB = 'cb';
export const STORE_NAME_BYTEBOT = 'bytebot';
export const STORE_NAME_CUSTOM = 'custom';
export const STORE_NAME_GPS = 'gps';

export const RAS_SUBSCRIPTION_TYPE_PERMANENT = 1;
export const RAS_SUBSCRIPTION_TYPE_PREPAID = 2;
export const RAS_SUBSCRIPTION_TYPE_POSTPAID = 3;
export const RAS_SUBSCRIPTION_TYPE_EXTENDED_TRIAL = 4;
export const RAS_SUBSCRIPTION_TYPE_INITIAL_TRIAL = 5;

export const PRODUCT_NAME_RAS = 'Parallels Remote Application Server'; // $t('Parallels Remote Application Server')
export const PRODUCT_NAME_PDB = 'Parallels Desktop for Mac Business Edition'; // $t('Parallels Desktop for Mac Business Edition')
export const PRODUCT_NAME_PDFC = 'Parallels Desktop for Chrome OS'; // $t('Parallels Desktop for Chrome OS')
export const PRODUCT_NAME_PTB = 'Parallels Toolbox'; // $t('Parallels Toolbox')
export const PRODUCT_NAME_PTBB = 'Parallels Toolbox Business Edition'; // $t('Parallels Toolbox Business Edition')
export const PRODUCT_NAME_PMM = 'Parallels Device Management'; // $t('Parallels Device Management')
export const PRODUCT_NAME_PMM_MDM = 'Parallels Mobile Device Management'; // $t('Parallels Mobile Device Management')
export const PRODUCT_NAME_PAX = 'Parallels Access'; // $t('Parallels Access')
export const PRODUCT_NAME_PAXB = 'Parallels Access Business'; // $t('Parallels Access Business')
export const PRODUCT_NAME_PAXS = 'Parallels Access for Helpdesk and Support'; // $t('Parallels Access for Helpdesk and Support')
export const PRODUCT_NAME_PDFM = 'Parallels Desktop for Mac'; // $t('Parallels Desktop for Mac')
export const PRODUCT_NAME_PDFM_ST = 'Parallels Desktop for Mac Standard Edition'; // $t('Parallels Desktop for Mac Standard Edition')
export const PRODUCT_NAME_PDFM_PRO = 'Parallels Desktop for Mac Pro Edition'; // $t('Parallels Desktop for Mac Pro Edition')
export const PRODUCT_NAME_PDL = 'Parallels Desktop App Store Edition'; // $t('Parallels Desktop App Store Edition')
export const PRODUCT_NAME_PTB_HDT = 'Parallels Hide Desktop'; // $t('Parallels Hide Desktop')
export const PRODUCT_NAME_PTB_DNST = 'Parallels Do Not Sleep Utility'; // $t('Parallels Do Not Sleep Utility')
export const PRODUCT_NAME_PTB_DVT = 'Parallels Video Downloader'; // $('Parallels Video Downloader')
export const PRODUCT_NAME_PTB_CVT = 'Parallels Video Converter'; // $t('Parallels Video Converter')
export const PRODUCT_NAME_MY_ACCOUNT = 'Parallels My Account'; // $t('Parallels My Account')
export const PRODUCT_NAME_PVAD = 'Parallels Cloud (Private Preview)'; // $t('Parallels Cloud (Private Preview)')
export const PRODUCT_NAME_PSW = 'Parallels Secure Workspace (formerly Awingu)'; // $t('Parallels Secure Workspace (formerly Awingu)')
export const PRODUCT_NAME_DAAS = 'Parallels DaaS'; // $t('Parallels DaaS')
export const PRODUCT_NAME_PBI = 'Parallels Browser Isolation'; // $t('Parallels Browser Isolation')
export const PRODUCT_NAME_RAS_AZMP = 'Parallels RAS Azure Marketplace Edition'; // $t('Parallels RAS Azure Marketplace Edition')
export const PRODUCT_NAME_PDE = 'Parallels Desktop for Mac Enterprise Edition'; // $t('Parallels Desktop for Mac Enterprise Edition')

export const PRODUCT_ROUTE_AWG = 'awingu';

const PRODUCT_KEY_TO_NAME: Dictionary<string> = {};
PRODUCT_KEY_TO_NAME[PRODUCT_KEY_NAME_RAS] = PRODUCT_NAME_RAS;
PRODUCT_KEY_TO_NAME[PRODUCT_KEY_NAME_PDB] = PRODUCT_NAME_PDB;
PRODUCT_KEY_TO_NAME[PRODUCT_KEY_NAME_PDB_PER_USER] = PRODUCT_NAME_PDB;
PRODUCT_KEY_TO_NAME[PRODUCT_KEY_NAME_PDFC] = PRODUCT_NAME_PDFC;
PRODUCT_KEY_TO_NAME[PRODUCT_KEY_NAME_PTB] = PRODUCT_NAME_PTB;
PRODUCT_KEY_TO_NAME[PRODUCT_KEY_NAME_PTBB] = PRODUCT_NAME_PTBB;
PRODUCT_KEY_TO_NAME[PRODUCT_KEY_NAME_PMM] = PRODUCT_NAME_PMM;
PRODUCT_KEY_TO_NAME[PRODUCT_KEY_NAME_PMM_MDM] = PRODUCT_NAME_PMM_MDM;
PRODUCT_KEY_TO_NAME[PRODUCT_KEY_NAME_PAX] = PRODUCT_NAME_PAX;
PRODUCT_KEY_TO_NAME[PRODUCT_KEY_NAME_PAXB] = PRODUCT_NAME_PAXB;
PRODUCT_KEY_TO_NAME[PRODUCT_KEY_NAME_PAXS] = PRODUCT_NAME_PAXS;
PRODUCT_KEY_TO_NAME[PRODUCT_KEY_NAME_PDFM] = PRODUCT_NAME_PDFM;
PRODUCT_KEY_TO_NAME[PRODUCT_KEY_NAME_PDPRO] = PRODUCT_NAME_PDFM;
PRODUCT_KEY_TO_NAME[PRODUCT_KEY_NAME_PDFM_PRO] = PRODUCT_NAME_PDFM;
PRODUCT_KEY_TO_NAME[PRODUCT_KEY_NAME_PDL] = PRODUCT_NAME_PDL;
PRODUCT_KEY_TO_NAME[PRODUCT_KEY_NAME_PTB_HDT] = PRODUCT_NAME_PTB_HDT;
PRODUCT_KEY_TO_NAME[PRODUCT_KEY_NAME_PTB_DNST] = PRODUCT_NAME_PTB_DNST;
PRODUCT_KEY_TO_NAME[PRODUCT_KEY_NAME_PTB_DVT] = PRODUCT_NAME_PTB_DVT;
PRODUCT_KEY_TO_NAME[PRODUCT_KEY_NAME_PTB_CVT] = PRODUCT_NAME_PTB_CVT;
PRODUCT_KEY_TO_NAME[PRODUCT_KEY_NAME_MY_ACCOUNT] = PRODUCT_NAME_MY_ACCOUNT;
PRODUCT_KEY_TO_NAME[PRODUCT_KEY_NAME_PVAD] = PRODUCT_NAME_PVAD;
PRODUCT_KEY_TO_NAME[PRODUCT_KEY_NAME_PSW] = PRODUCT_NAME_PSW;
PRODUCT_KEY_TO_NAME[PRODUCT_KEY_NAME_DAAS] = PRODUCT_NAME_DAAS;
PRODUCT_KEY_TO_NAME[PRODUCT_KEY_NAME_PBI] = PRODUCT_NAME_PBI;
PRODUCT_KEY_TO_NAME[PRODUCT_KEY_NAME_RAS_AZMP] = PRODUCT_NAME_RAS_AZMP;
PRODUCT_KEY_TO_NAME[PRODUCT_KEY_NAME_PDE] = PRODUCT_NAME_PDE;

// alias
PRODUCT_KEY_TO_NAME.toolbox = PRODUCT_KEY_TO_NAME[PRODUCT_KEY_NAME_PTB];

const RESOURCE_KEY_TO_NAME: Dictionary<string> = {};
RESOURCE_KEY_TO_NAME[RKN_UPGRADE_INSURANCE] = 'Upgrade Insurance'; // $t('Upgrade Insurance')
RESOURCE_KEY_TO_NAME[RKN_STD_SUPPORT] = 'Support'; // $t('Support')
RESOURCE_KEY_TO_NAME[AWG_MAINTENANCE_SUPPORT] = 'Maintenance and Support'; // $t('Maintenance and Support')
RESOURCE_KEY_TO_NAME[MAINTENANCE_SUPPORT] = 'Maintenance and Support'; // $t('Maintenance and Support')

export function prodKeyToName (prodKey: string) : string {
  return PRODUCT_KEY_TO_NAME[prodKey] || null;
}

export function resourceToName (keyName: string) : string {
  return RESOURCE_KEY_TO_NAME[keyName] || null;
}

export const CB_TRANSACTION_STATES = {
  default: 'payment_received',
  cardExpiring: 'credit_card_expiring',
  initialRefund: 'initial_purchase_refunded',
  renew: {
    refund: 'renew_purchase_refunded',
    offline: 'renew_offline_payment_applied',
    decline: 'renew_online_declined'
  },
  upgrade: {
    refund: 'upgrade_purchase_refunded',
    offline: 'upgrade_offline_payment_applied',
    decline: 'upgrade_online_declined'
  },
  increase: {
    refund: 'increase_quantity_purchase_refunded',
    offline: 'increase_quantity_offline_payment_applied',
    decline: 'increase_quantity_online_declined'
  }
};

export const RESOURCE_UNIT_HOST = 'host';
export const RESOURCE_UNIT_COMPUTER = 'computer';
export const RESOURCE_UNIT_USER = 'user';
export const RESOURCE_UNIT_CONCURRENT_CONNECTION = 'concurrent_connection';
export const RESOURCE_UNIT_MOBILE_DEVICE = 'mobile_device';
export const RESOURCE_UNIT_MANAGED_DEVICE = 'managed_device';
export const RESOURCE_UNIT_CONCURRENT_USER = 'concurrent_user';
export const RESOURCE_UNIT_NAMED_USER = 'named_user';
export const RESOURCE_UNIT_DATETIME = 'datetime';

export const BUSINESS_PRODUCTS = 'business';
export const PERSONAL_PRODUCTS = 'personal';

export const PRODUCT_UNITS = {
  COMPUTERS: 'computers', // $t('computers')
  MOBILE_DEVICES: 'mobile devices', // $t('mobile devices')
  MANAGED_COMPUTERS: 'managed computers', // $t('managed computers')
  CONCURRENT_USERS: 'concurrent users', // $t('concurrent users')
  NAMED_USERS: 'named users', // $t('named users')
  USERS: 'users', // $t('users')
  LICENSE_SEATS: 'license seats' // $t('license_seats')
};

export const HOST_STATUS_ACTIVE = 'Active'; // $t('Active')
export const HOST_STATUS_INACTIVE = 'Inactive'; // $t('Inactive')
export const HOST_STATUS_BLACKLISTED = 'Blacklisted'; // $t('Blacklisted')
export const HOST_STATUS_EXPIRED = 'Expired'; // $t('Expired')
export const HOST_STATUS_DEACTIVATED = 'Deactivated'; // $t('Deactivated')
export const HOST_STATUS_ACTIVE_OFFLINE = 'Active (Offline)'; // $t('Active (Offline)')

export const CHANNEL_NAMES = {
  sales: 'Parallels Sales Team', // $t('Parallels Sales Team')
  online: 'Online Store', // $t('Online Store')
  oem_partner: 'OEM Partner' // $t('OEM Partner')
};

export const TRAIT_RAS_UPGRADED_SUPPORT_TYPE_NO_SUPPORT = 0;
export const TRAIT_RAS_UPGRADED_SUPPORT_TYPE_SENIOR = 1;
export const TRAIT_RAS_UPGRADED_SUPPORT_TYPE_PREMIER = 2;

export const TRAIT_RAS_UPGRADED_SUPPORT_TYPE_NO_SUPPORT_NAME = 'No Upgraded Support'; // $t('No Upgraded Support')
export const TRAIT_RAS_UPGRADED_SUPPORT_TYPE_SENIOR_NAME = 'Senior Support'; // $t('Senior Support')
export const TRAIT_RAS_UPGRADED_SUPPORT_TYPE_PREMIER_NAME = 'Premier Support'; // $t('Premier Support')

export const RAS_UPGRADED_SUPPORT_TYPES = {
  [TRAIT_RAS_UPGRADED_SUPPORT_TYPE_NO_SUPPORT]: TRAIT_RAS_UPGRADED_SUPPORT_TYPE_NO_SUPPORT_NAME,
  [TRAIT_RAS_UPGRADED_SUPPORT_TYPE_SENIOR]: TRAIT_RAS_UPGRADED_SUPPORT_TYPE_SENIOR_NAME,
  [TRAIT_RAS_UPGRADED_SUPPORT_TYPE_PREMIER]: TRAIT_RAS_UPGRADED_SUPPORT_TYPE_PREMIER_NAME
};

export const RAS_UPGRADED_SUPPORT_STATUS_EXPIRED = 'expired'; // $t('expired')

export enum AutomaticLicensesRevocationPeriod {
  NEVER = 'never',
  ONE_MONTH = '1 month',
  QUARTER = '3 months',
  HALF_YEAR = '6 months',
  ONE_YEAR = '12 months'
}

export const ALLOWED_DOWNLOAD_LICENSE_KEY = [PRODUCT_KEY_NAME_PSW];

export enum RasUpgradedSupportType {
  NO_SUPPORT = TRAIT_RAS_UPGRADED_SUPPORT_TYPE_NO_SUPPORT,
  SENIOR = TRAIT_RAS_UPGRADED_SUPPORT_TYPE_SENIOR,
  PREMIER = TRAIT_RAS_UPGRADED_SUPPORT_TYPE_PREMIER
}

export const RasUpgradedSupportTypeToResource = {
  [RasUpgradedSupportType.SENIOR]: RESOURCE_RAS_SENIOR_SUPPORT_KEY_NAME,
  [RasUpgradedSupportType.PREMIER]: RESOURCE_RAS_PREMIER_SUPPORT_KEY_NAME
};
